import React from 'react'
import Footer from '../../components/Footer/Footer';
import { LocaleTypes } from '../../data/locales';
import IndexLayout from '../../layouts';

import { mx_es, mx_en } from '../../content/legalterms/termsconditions'
import { FormattedMessage } from 'react-intl';

interface ConditionTermsMxProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const mexico: React.FC<ConditionTermsMxProps> = ({pathContext, location}) => {
  const content = () => (
    <>
      <section className="legal-hero">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
            <h1><FormattedMessage id="condition_terms.country.mx.title"/></h1>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div
            className="markdown-content pb-7"
            dangerouslySetInnerHTML={{ __html: pathContext.localeCode === 'es' ? mx_es : mx_en }}
          />
        </div>
      </section>
      <Footer theme="gray" />
    </>
  )
  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default mexico